import { ERROR_CODE } from './constants'
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isTxRejectedError = (error: any) => {
  return (
    error &&
    [ERROR_CODE.ACTION_REJECTED, ERROR_CODE.USER_REJECTED].includes(error?.code)
  )
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const logTxError = (error: any) => {
  if (!error || isTxRejectedError(error)) {
    return
  }
  console.error(error)
}
